import React from 'react';
import styled from 'styled-components';
import useMode from '../../store/useMode';

const Page = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 70%;
    }
    p {
        text-align: center;
        font-size: 1.25rem;
    }
`;

const Wip = () => {
    const wipSrc = useMode((state) => state.wipSrc);
    return (
        <Page>
            <StyledSection>
                <StyledDiv>
                    <img alt="Work In Progress" src={wipSrc} />
                    <p>This page in under construction</p>
                </StyledDiv>
            </StyledSection>
        </Page>
    );
};

export default Wip;
