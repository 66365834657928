import React from 'react';
import { useRouteError } from 'react-router-dom';

export const SystemError = () => {
    const err = useRouteError();
    const message = err instanceof Error ? err.message : '';
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{message}</i>
            </p>
        </div>
    );
};
