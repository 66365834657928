import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.h2`
    display: flex;
    font-weight: 800;
    font-size: 40px;
    column-gap: 10px;
    .first {
        color: #ff9933;
    }
    .mid {
        color: ${({ theme }) => theme.MID_NAME};
    }
    .last {
        color: #138808;
    }
`;

const OrgName = () => {
    return (
        <StyledHeader>
            <span className="first">Innovation</span>
            <span className="mid">&</span>
            <span className="last">Idea</span>
        </StyledHeader>
    );
};

export default OrgName;
