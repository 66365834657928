import React from 'react';
import { Helmet } from 'react-helmet-async';

interface seoProps {
    title: string;
    description: string;
    name: string;
    type: string;
    image?: string;
    url?: string;
}

const Seo = (props: seoProps) => {
    const { title, description, name, type } = props;
    const image = props.image || 'https://www.innodea.us/images/logo-dark.svg';
    const url = props.url || 'https://www.innodea.us';
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* End standard metadata tags */}
            {/* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />
            {/* End Facebook tags */}
            {/* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/* End Twitter tags */}
        </Helmet>
    );
};

export default Seo;
