
import { base } from "./base";
import { node } from "./node";

export const darkTheme = {
  ...base,
  ...node.dark,
  BLACK_SECONDARY: "#23272A",
  SILVER_DARK: "#4D4D4D",
  NODE_KEY: "#FAA81A",
  OBJECT_KEY: "#59b8ff",
  SIDEBAR_ICONS: "#8B8E90",

  INTERACTIVE_NORMAL: "#b9bbbe",
  INTERACTIVE_HOVER: "#dcddde",
  INTERACTIVE_ACTIVE: "#fff",
  BACKGROUND_NODE: "#2B2C3E",
  BACKGROUND_TERTIARY: "#202225",
  BACKGROUND_SECONDARY: "#2f3136",
  BACKGROUND_PRIMARY: "#090E34",
  BACKGROUND_MODIFIER_ACCENT: "rgba(79,84,92,0.48)",
  MODAL_BACKGROUND: "#36393E",
  TEXT_NORMAL: "#ececf1",
  TEXT_POSITIVE: "hsl(139,calc(var(--saturation-factor, 1)*51.6%),52.2%)",
  BORDER: "#6B8096",
  MENU_BACKGROUND:"rgb(29 33 68/1)",
  SVG_OPACITY:30,
  BACKGROUND_HIGHLIGHT: "#002e4d",
  MENU_BAR: "#171949",
  MID_NAME:"#ffffff",
  BUTTON_COLOR:" #4a6cf7",
  BUTTON_TEXT: "#ececf1",
};
export const lightTheme = {
  ...base,
  ...node.light,
  BLACK_SECONDARY: "#F2F2F2",
  SILVER_DARK: "#CCCCCC",
  NODE_KEY: "#DC3790",
  OBJECT_KEY: "#0260E8",
  SIDEBAR_ICONS: "#6D6E70",

  INTERACTIVE_NORMAL: "#4f5660",
  INTERACTIVE_HOVER: "#2e3338",
  INTERACTIVE_ACTIVE: "#060607",
  BACKGROUND_NODE: "#FAFAFA",
  BACKGROUND_TERTIARY: "#e3e5e8",
  BACKGROUND_SECONDARY: "#f2f3f5",
  BACKGROUND_PRIMARY: "#FFFFFF",
  BACKGROUND_MODIFIER_ACCENT: "rgba(106,116,128,0.24)",
  MODAL_BACKGROUND: "#FFFFFF",
  TEXT_NORMAL: "#343541",
  TEXT_POSITIVE: "#008736",
  BORDER: "#959CB1",
  MENU_BACKGROUND:"rgb(242, 243, 245)",
  SVG_OPACITY:100,
  BACKGROUND_HIGHLIGHT:"#ffffb3",
  MENU_BAR: "#f7f7f7",
  MID_NAME: "#09068E",
  BUTTON_COLOR:" #4a6cf7",
  BUTTON_TEXT: "#ececf1",
};

