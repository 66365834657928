import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Editor, { Monaco } from '@monaco-editor/react';
import useJson from '../../store/useJson';
import useMode from '../../store/useMode';
import InputHeader from './InputHeader';

const InputEditor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        height: 50%;
    }
`;

const Input = ({
    handleChange,
}: {
    handleChange: (value?: string) => void;
}) => {
    const mode = useMode((state) => state.mode);
    const inputJson = useJson((state) => state.inputJson);
    const language = useJson((state) => state.language);
    const wordWrap = useJson((state) => state.wordWrap);
    const setError = useJson((state) => state.setError);
    const getHasChanges = useJson((state) => state.getHasChanges);
    const editorOptions = {
        wordWrap: wordWrap ? ('on' as const) : ('off' as const),
        minimap: {
            enabled: false,
        },
    };
    const handleBeforeMount = useCallback(
        (monaco: Monaco) => {
            monaco.editor.defineTheme('app-dark', {
                base: 'vs-dark',
                inherit: true,
                rules: [],
                colors: {
                    'editor.background': '#090E34',
                },
            });

            if ('json' === language.value) {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                    allowComments: true,
                    comments: 'ignore',
                });
            }
            monaco.editor.onDidChangeMarkers(([uri]) => {
                const markers = monaco.editor.getModelMarkers({
                    resource: uri,
                });
                setError(!!markers.length);
            });
        },
        [setError, language]
    );

    useEffect(() => {
        const beforeunload = (e: BeforeUnloadEvent) => {
            if (getHasChanges()) {
                const confirmationMessage =
                    'Unsaved changes, if you leave before saving  your changes will be lost';
                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage;
            }
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, [getHasChanges]);

    return (
        <InputEditor>
            <InputHeader />
            <Editor
                value={inputJson}
                theme={mode === 'dark' ? 'app-dark' : 'light'}
                options={editorOptions}
                beforeMount={handleBeforeMount}
                onChange={handleChange}
                language={language.value}
                height="100%"
            />
        </InputEditor>
    );
};

export default Input;
