import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useJson from '../../store/useJson';
import useMode from '../../store/useMode';
import Input from './input';
import Output from './output';
import Seo from '../../components/seo';

const Page = styled.div`
    display: flex;
    flex-direction: row;
    height: 80%;
    justify-content: center;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Notice = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${({ theme }) => theme.MENU_BAR};
    color: ${({ theme }) => theme.TEXT_NORMAL};
`;

const LangEditor = () => {
    const fetchJson = useJson((state) => state.fetchJson);
    const outputJson = useJson((state) => state.output);
    const mode = useMode((state) => state.mode);
    const language = useJson((state) => state.language);
    const [data, setData] = useState<string>('');

    useEffect(() => {
        fetchJson('');
        setData(outputJson);
    }, [fetchJson, outputJson]);

    const handleChange = useCallback((value?: string) => {
        try {
            let parsedJson = '';
            if (value && value?.trim.length === 0) {
                parsedJson = JSON.stringify(JSON.parse(value!), null, 2);
            }
            setData(parsedJson);
        } catch (error) {}
    }, []);

    return (
        <>
            <Seo
                title="Formatter & Validator - JSON / Typescript / HTML / CSS "
                description="Developer tools for formatting & Validating JSON / Typescript / HTML / CSS improve readability, productivity and efficiency."
                name="Online Dev tools"
                type="Productivity"
            />
            <Page>
                <Input handleChange={handleChange} />
                <Output mode={mode} language={language.value} data={data} />
            </Page>
            <Notice>We never save the JSON/Code provided by user</Notice>
        </>
    );
};

export default LangEditor;
