import React, { useState } from 'react';
import styled from 'styled-components';
import menuData from '../header/menuData';

const StyledUnordered = styled.ul<{ open: boolean }>`
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    width: 100%;
    div {
        display: flex;
        justify-content: space-between;
    }
    li {
        padding: 18px 10px;
        &:hover {
            opacity: 0.7;
        }
        a {
            color: ${({ theme }) => theme.TEXT_NORMAL};
            display: inline-flex;
            text-decoration: none;
        }
    }

    .contact {
        display: flex;
        background-color: ${({ theme }) => theme.BUTTON_COLOR};
        font-weight: 700;
        border-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
        border-radius: 1rem;
        flex-bias: 120px;
        justify-content: center;
        a {
            color: rgb(220, 221, 222);
        }
    }

    @media (max-width: 768px) {
        justify-content: normal;
        align-items: normal;
        flex-flow: column nowrap;
        background: transparent;
        position: fixed;
        transform: ${({ open }) =>
            open ? 'translateX(0)' : 'translateX(100%)'};
        top: 0;
        right: 0;
        width: 200px;
        padding-top: 3.5rem;
        transition: transform 0.3s ease-in-out;
        li {
            background-color: ${({ theme }) => theme.MENU_BACKGROUND};
        }
        div {
            display: flex;
            flex-direction: column;
        }
        .contact {
            border-style: unset;
        }
    }
`;

const RightNav = ({ open }: { open: boolean }) => {
    // submenu handler
    const [openIndex, setOpenIndex] = useState(-1);
    const handleSubmenu = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(-1);
        } else {
            setOpenIndex(index);
        }
    };
    return (
        <StyledUnordered open={open}>
            <div>
                {menuData.map((menuItem, index) => (
                    <li key={menuItem.id}>
                        {menuItem.path ? (
                            <a href={menuItem.path}>
                                {menuItem.title as string}
                            </a>
                        ) : (
                            <a href=" " onClick={() => handleSubmenu(index)}>
                                {menuItem.title as string}
                            </a>
                        )}
                    </li>
                ))}
            </div>
            <div>
                <li key="/contact" className="contact">
                    <a href="contact"> Contact Us </a>
                </li>
            </div>
        </StyledUnordered>
    );
};

export default RightNav;
