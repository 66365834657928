import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import useJson from '../../store/useJson';
import { MdOutlineErrorOutline, MdCheckCircleOutline } from 'react-icons/md';

const StyledBar = styled.div`
    background: ${({ theme }) => theme.MENU_BAR};
    padding-left: 16px;
    padding-right: 16px;
`;
const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
`;

const StyledValidation = styled.span<{ error: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 5px;
    padding-left: 16px;
    font-size: 16px;
    color: ${({ theme, error }) =>
        error ? theme.TEXT_DANGER : theme.TEXT_POSITIVE};
`;

const CopyButton = styled.div<{ showIcon?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Icon = styled.div<{ tooltip?: string; hide?: boolean }>`
    svg {
        display: ${({ hide }) => (hide ? 'none' : 'relative')};
    }
    &:hover {
        display: block;
    }
`;

const OutputHeader = ({ data }: { data: string }) => {
    const hasError = useJson((state) => state.hasError);
    const language = useJson((state) => state.language);
    const theme = useContext(ThemeContext);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const copy = () => {
        // Set the clipboard contents.
        navigator.clipboard.writeText(data || '');
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    return (
        <StyledBar>
            <StyledDiv>
                <StyledValidation error={hasError}>
                    {hasError ? (
                        <MdOutlineErrorOutline size={20} />
                    ) : (
                        <MdCheckCircleOutline size={20} />
                    )}
                    {hasError ? 'Invalid' : 'Valid '} {language.text}
                </StyledValidation>
                <CopyButton onClick={copy}>
                    <Icon hide={isCopied}>
                        <svg
                            aria-hidden="true"
                            height="16"
                            viewBox="0 0 16 16"
                            version="1.1"
                            width="16"
                        >
                            <path
                                fill={theme.TEXT_NORMAL}
                                opacity="1.000000"
                                stroke="none"
                                d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 0 1 0 1.5h-1.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 1.5 0v1.5A1.75 1.75 0 0 1 9.25 16h-7.5A1.75 1.75 0 0 1 0 14.25Z"
                            ></path>
                            <path
                                fill={theme.TEXT_NORMAL}
                                opacity="1.000000"
                                stroke="none"
                                d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0 1 14.25 11h-7.5A1.75 1.75 0 0 1 5 9.25Zm1.75-.25a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25Z"
                            ></path>
                            <title>Copy</title>
                        </svg>
                    </Icon>
                    <Icon hide={!isCopied}>
                        <svg
                            fill={theme.TEXT_POSITIVE}
                            height="16"
                            viewBox="0 0 16 16"
                            version="1.1"
                            width="16"
                        >
                            <path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path>
                            <title>Copied</title>
                        </svg>
                    </Icon>
                </CopyButton>
            </StyledDiv>
        </StyledBar>
    );
};

export default OutputHeader;
