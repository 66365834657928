export const node = {
    dark: {
      NODE_COLORS: {
        TEXT: "#35D073",
        NODE_KEY: "#59b8ff",
        NODE_VALUE: "#DCE5E7",
        INTEGER: "#e8c479",
        NULL: "#939598",
        BOOL: {
          FALSE: "#F85C50",
          TRUE: "#00DC7D",
        },
        PARENT_ARR: "#FC9A40",
        PARENT_OBJ: "#59b8ff",
        CHILD_COUNT: "white",
      },
    },
    light: {
      NODE_COLORS: {
        TEXT: "#748700",
        NODE_KEY: "#761CEA",
        NODE_VALUE: "#535353",
        INTEGER: "#A771FE",
        NULL: "#afafaf",
        BOOL: {
          FALSE: "#FF0000",
          TRUE: "#748700",
        },
        PARENT_ARR: "#FF6B00",
        PARENT_OBJ: "#761CEA",
        CHILD_COUNT: "#535353",
      },
    },
  };