import { create } from 'zustand';

type ModeActions = {
    setMode: (mode: string) => void;
    toggle: (mode: string) => void;
};

const initialState = {
    mode: 'dark',
    logoSrc: 'images/logo-dark.svg',
    wipSrc: 'images/wip-dark.svg',
};

export type ModeState = typeof initialState;

const useMode = create<ModeState & ModeActions>()((set, get) => ({
    ...initialState,
    setMode: (mode) => {
        set({ mode });
    },
    toggle: (mode: string) => {
        set({
            mode: mode === 'dark' ? 'light' : 'dark',
            logoSrc:
                mode === 'dark' ? 'images/logo.svg' : 'images/logo-dark.svg',
            wipSrc: mode === 'dark' ? 'images/wip.svg' : 'images/wip-dark.svg',
        });
    },
}));

export default useMode;
