import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Footer = () => {
    return <StyledFooter>© 2023 INNODEA. ALL RIGHTS RESERVED.</StyledFooter>;
};

export default Footer;
