export const base = {
    CRIMSON: "#DC143C",
    BLURPLE: "#5865F2",
    PURPLE: "#9036AF",
    FULL_WHITE: "#FFFFFF",
    BLACK: "#202225",
    BLACK_DARK: "#2C2F33",
    BLACK_LIGHT: "#2F3136",
    BLACK_PRIMARY: "#36393f",
    DARK_SALMON: "#E9967A",
    DANGER: "hsl(359,calc(var(--saturation-factor, 1)*66.7%),54.1%)",
    LIGHTGREEN: "#90EE90",
    SEAGREEN: "#11883B",
    ORANGE: "#FAA81A",
    SILVER: "#B9BBBE",
    PRIMARY: "#4D4D4D",
    TEXT_DANGER: "#db662e",
  };