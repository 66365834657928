import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from '../../components/layout';
import LangEditor from '../../containers/LangEditor';
import Home from '../../components/home';
import Wip from '../../components/wip';
import { SystemError } from '../../components/error';
import Contact from '../../components/contact';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <SystemError />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: 'devtool',
                element: <LangEditor />,
            },
            {
                path: 'contact',
                element: <Contact />,
            },
            {
                path: 'signin',
                element: <Wip />,
            },
            {
                path: 'signup',
                element: <Wip />,
            },
        ],
    },
]);

const Routing = () => {
    return <RouterProvider router={router} />;
};

export default Routing;
