import React from 'react';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { lightTheme, darkTheme } from '../theme';
import useMode from '../store/useMode';
import Routing from './routing';
import { GlobalStyles } from './GlobalStyles';

const App = () => {
    const mode = useMode((state) => state.mode);
    const themeMode = mode === 'light' ? lightTheme : darkTheme;

    return (
        <HelmetProvider>
            <ThemeProvider theme={themeMode}>
                <>
                    <GlobalStyles />
                    <div id="App" data-testid="app-container">
                        <Routing />
                    </div>
                </>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
