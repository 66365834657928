import JSON5 from 'json5';

const sampleJson = Object.freeze({
  companyName: 'Innodea',
  city: 'Raleigh',
  formed: 2022,
  active: true,
  members: [
    {
      name: 'Sweety Saurabh',
      designation: 'Senior Developer',
      focusArea: 'UI Development, Test Automation',
      skills: ['ReactJS', 'Selenium', 'cucumber', 'Pega'],
    },
    {
      name: 'John Doe',
      designation: 'Developer',
      focusArea: 'Java Backend, Microservices',
      skills: ['Springboot', 'Junit', 'AWS', 'Cassandra', 'MongoDB'],
    },
  ],
});

export const defaultJson = JSON.stringify(sampleJson, null, 0);
export const defaultOutput = JSON.stringify(sampleJson, null, 2);
export const defaultJson5 = JSON5.stringify(sampleJson, null, 2);
