import { create } from 'zustand';
import { defaultJson, defaultOutput } from '../constants/data';

type JsonActions = {
    setJson: (json: string) => void;
    getJson: () => string;
    setOutput: (json: string) => void;
    getOutput: () => string;
    fetchJson: (jsonId: string | string[] | undefined) => void;
    setError: (hasError: boolean) => void;
    getHasChanges: () => boolean;
    setWordWrap: (wordWrap: boolean) => void;
    setLanguage: (langValue: string) => void;
};

const initialState = {
    data: null,
    inputJson: '',
    output: '',
    loading: true,
    hasChanges: false,
    hasError: false,
    wordWrap: false,
    language: { key: 'json', value: 'json', text: 'JSON' },
    languages: [
        { key: 'json', value: 'json', text: 'JSON' },
        { key: 'typescript', value: 'typescript', text: 'Typescript' },
        { key: 'html', value: 'html', text: 'HTML' },
        { key: 'css', value: 'css', text: 'CSS' },
    ],
};

export type JsonState = typeof initialState;

const useJson = create<JsonState & JsonActions>()((set, get) => ({
    ...initialState,
    setLanguage: (langValue) => {
        const state = get();
        const language = state.languages.find((obj) => obj.value === langValue);
        console.log('language=', language);
        set({ language });
    },
    setJson: (inputJson) => {
        set({ inputJson, hasChanges: true });
    },
    getJson: () => get().inputJson,
    setOutput: (output) => {
        set({ output });
    },
    getOutput: () => get().output,
    fetchJson: async (value) => {
        if (value === '' || value === defaultJson) {
            const state = get();
            if (state.language.value === 'json') {
                set({
                    inputJson: defaultJson,
                    output: defaultOutput,
                    loading: false,
                });
            }
        }
    },
    setError: (hasError: boolean) => set({ hasError }),
    setWordWrap: (wordWrap: boolean) => {
        set({ wordWrap });
    },
    getHasChanges: () => get().hasChanges,
}));

export default useJson;
