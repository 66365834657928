import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div<{ direction?: string }>`
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
    align-items: center;
    justify-content: center;
    span {
        color: ${({ theme }) => theme.TEXT_NORMAL};
    }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    display: flex;
    flex-direction: row;
`;

type checkboxProps = {
    id: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
    direction?: string;
};

const Checkbox = (props: checkboxProps) => {
    const { id, checked, onChange, label, direction } = props;
    return (
        <StyledDiv direction={direction}>
            <label htmlFor={id}>{label}</label>
            <StyledCheckbox
                id={id}
                checked={checked}
                onChange={() => onChange(!checked)}
            />
        </StyledDiv>
    );
};

export default Checkbox;
