import React from 'react';
import styled from 'styled-components';
import useJson from '../../store/useJson';
import Checkbox from '../../components/checkbox.tsx';
import Dropdown from '../../components/dropdown.tsx';

export const StyledBar = styled.div`
    background: ${({ theme }) => theme.MENU_BAR};
    padding-left: 16px;
    padding-right: 16px;
`;
export const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
`;

const InputHeader = () => {
    const wordWrap = useJson((state) => state.wordWrap);
    const language = useJson((state) => state.language);
    const languages = useJson((state) => state.languages);
    const setWordWrap = useJson((state) => state.setWordWrap);
    const setLanguage = useJson((state) => state.setLanguage);

    return (
        <StyledBar>
            <StyledDiv>
                <Dropdown
                    id="language"
                    selected={language.value}
                    items={languages}
                    onChange={setLanguage}
                    label="Format"
                />
                <Checkbox
                    id="wordWrap"
                    checked={wordWrap}
                    onChange={setWordWrap}
                    label="Word Wrap"
                    direction="row-reverse"
                />
            </StyledDiv>
        </StyledBar>
    );
};

export default InputHeader;
