import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMode from '../../store/useMode';
import Toggle from '../toggle';
import Nav from '../nav';

const StyledHeader = styled.nav<{ sticky: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: ${({ sticky }) => (sticky ? 1 : 0.8)};
    position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
    z-index: 9999;
    // overflow: hidden;
    img {
        height: inherit;
        width: 240px;
    }
`;

const Header = () => {
    // Sticky Navbar
    const [sticky, setSticky] = useState(false);
    const handleStickyNavbar = () => {
        if (window.scrollY >= 80) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleStickyNavbar);
    });

    const mode = useMode((state) => state.mode);
    const logoSrc = useMode((state) => state.logoSrc);
    const toggle = useMode((state) => state.toggle);

    return (
        <>
            <StyledHeader sticky={sticky}>
                <img alt="innodea" src={logoSrc} />
                <Nav />
                <Toggle mode={mode} action={toggle} />
            </StyledHeader>
        </>
    );
};

export default Header;
