import React from 'react';
import Header from '../header';
import Footer from '../footer';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 98%;
    height: 98%;
    align-items: stretch;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
    box-sizing: border-box;
`;

const Layout = () => {
    return (
        <>
            <LayoutContainer>
                <Header />
                <Outlet />
                <Footer />
            </LayoutContainer>
        </>
    );
};

export default Layout;
