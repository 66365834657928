type menuType =  {
    id: number;
    title: string;
    newTab: boolean;
    path?: string;
    submenu?: {
        id: number;
        title: string;
        path: string;
        newTab: boolean;
    }[];
}

const menuData : menuType [] = [
  {
    id: 1,
    title: "Home",
    path: "/",
    newTab: false,
  },
  {
    id: 2,
    title: "Dev Tool",
    path: "/devtool",
    newTab: false,
  },
  
];
export default menuData;
