import React from 'react';
import styled from 'styled-components';
import Editor from '@monaco-editor/react';
import OutputHeader from './outputHeader';

const OutputEditor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        height: 50%;
    }
`;

const Output = ({
    mode,
    language,
    data,
}: {
    mode: string;
    language: string;
    data: string;
}) => {
    const editorOptions = {
        formatOnPaste: true,
        formatOnType: true,
        readOnly: true,
        domReadOnly: true,
        minimap: {
            enabled: false,
        },
    };
    return (
        <OutputEditor>
            <OutputHeader data={data} />
            <Editor
                value={data}
                theme={mode === 'dark' ? 'app-dark' : 'light'}
                options={editorOptions}
                language={language}
                height="100%"
            />
        </OutputEditor>
    );
};

export default Output;
