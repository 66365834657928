import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div<{ direction?: string }>`
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
    label {
        padding-left: 8px;
        padding-right: 8px;
    }
`;
const StyledDropdown = styled.select`
    display: flex;
    background-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
    color: ${({ theme }) => theme.TEXT_POSITIVE};
    border: 0;
    padding-top: 2px;
`;

type dropdownProps = {
    id: string;
    selected?: string;
    items: { key: string; value: string; text?: string }[];
    onChange: (selected: string) => void;
    label: string;
    direction?: string;
};

const Dropdown = (props: dropdownProps) => {
    const { id, selected, items, onChange, label, direction } = props;
    return (
        <StyledDiv direction={direction}>
            <label htmlFor={id}>{label}</label>
            <StyledDropdown
                id={id}
                onChange={(event) => onChange(event.target.value)}
                value={selected}
            >
                {items.map((item) => (
                    <option key={item.key} value={item.value}>
                        {item.text ? item.text : item.value}
                    </option>
                ))}
            </StyledDropdown>
        </StyledDiv>
    );
};

export default Dropdown;
